import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const DomainVerificationConfirmModal = lazy(() => import('.'));

class DomainVerificationConfirmModalWebComponent extends ReactWebComponent {
    render() {
        return (
            <RootProviders>
                <DomainVerificationConfirmModal />
            </RootProviders>
        );
    }
}

customElements.define('domain-verification-confirm-modal', DomainVerificationConfirmModalWebComponent);
