import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const FileExplorer = lazy(() => import('./FileExplorerContainer'));

class FileExplorerComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['projectId', 'isAdmin', 'productType'];
    }

    render() {
        const projectId = this.prop('projectId') ?? '';
        const isAdmin = this.prop('isAdmin') === '1';
        const projectType = parseInt(this.prop('projectType') ?? '-1', 10);

        return (
            <AppProviders>
                <FileExplorer
                    projectId={projectId}
                    isAdmin={isAdmin}
                    projectType={projectType}
                    initiateFileExplorerListeners={() => {
                        document.dispatchEvent(new Event('initfileexplorerlisteners'));
                    }}
                />
            </AppProviders>
        );
    }
}

customElements.define('file-explorer', FileExplorerComponent);
