import { type ReactNode, useLayoutEffect, useMemo, useState } from 'react';

import { SubscriptionContext, type SubscriptionContextValue } from './SubscriptionContext';

const SubscriptionContextProvider = ({ children }: { children: ReactNode }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubscriptionInProgress, setIsSubscriptionInProgress] = useState(false);
    const [planFormValid, setPlanFormValid] = useState<boolean>(false);
    const [billingDetailsFormValid, setBillingDetailsFormValid] = useState<boolean>(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [inAuthStep, setInAuthStep] = useState(false);

    useLayoutEffect(() => {
        if (isSubmitting && (!billingDetailsFormValid || !planFormValid)) {
            setIsSubmitting(false);
        }

        if (isSubmitting && billingDetailsFormValid && planFormValid) {
            setIsConfirmationOpen(true);
            setIsSubmitting(false);
        }
    }, [isSubmitting, billingDetailsFormValid, planFormValid]);

    const memoizedValue = useMemo(
        () =>
            ({
                isSubmitting,
                setIsSubmitting,
                isSubscriptionInProgress,
                setIsSubscriptionInProgress,
                planFormValid,
                setPlanFormValid,
                billingDetailsFormValid,
                setBillingDetailsFormValid,
                isConfirmationOpen,
                setIsConfirmationOpen,
                inAuthStep,
                setInAuthStep,
            }) satisfies SubscriptionContextValue,
        [
            isSubmitting,
            setIsSubmitting,
            isSubscriptionInProgress,
            setIsSubscriptionInProgress,
            planFormValid,
            setPlanFormValid,
            billingDetailsFormValid,
            setBillingDetailsFormValid,
            isConfirmationOpen,
            setIsConfirmationOpen,
            inAuthStep,
            setInAuthStep,
        ],
    );

    return <SubscriptionContext.Provider value={memoizedValue}>{children}</SubscriptionContext.Provider>;
};

export default SubscriptionContextProvider;
