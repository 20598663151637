import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const EditorSearch = lazy(() => import('.'));

class EditorSearchWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['value', 'placeholder', 'loading', 'case-sensitive', 'exact-match', 'search-disabled'];
    }

    render() {
        const value = this.prop('value') || undefined;
        const placeholder = this.prop('placeholder') || undefined;
        const loading = this.prop('loading') === 'true';
        const caseSensitive = this.prop('case-sensitive') === 'true';
        const exactMatch = this.prop('exact-match') === 'true';
        const disabled = this.prop('search-disabled') === 'true';

        return (
            <RootProviders>
                <EditorSearch
                    onSearch={(query, options) => {
                        const editorSearchEvent = new CustomEvent('editor-search-query', {
                            detail: {
                                query,
                                options,
                            },
                        });
                        this.dispatchEvent(editorSearchEvent);
                    }}
                    onFocus={() => {
                        this.dispatchEvent(new CustomEvent('editor-search-focus'));
                    }}
                    value={value}
                    placeholder={placeholder}
                    loading={loading}
                    caseSensitive={caseSensitive}
                    exactMatch={exactMatch}
                    disabled={disabled}
                />
            </RootProviders>
        );
    }
}

customElements.define('editor-search', EditorSearchWebComponent);
