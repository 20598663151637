import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { AllLanguagesLanguage } from './types';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const ProjectsList = lazy(() => import('./components/ProjectsList'));

class ProjectListWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['all-languages', 'is-team-admin', 'is-in-user-group', 'is-search-enabled'];
    }

    render() {
        const allLanguages = this.jsonProp<AllLanguagesLanguage[]>('all-languages') ?? [];
        const isTeamAdmin = this.prop('is-team-admin') === 'true';
        const isInUserGroup = this.prop('is-in-user-group') === 'true';
        const isSearchEnabled = this.prop('is-search-enabled') === 'true';

        return (
            <AppProviders>
                <ProjectsList
                    allLanguages={allLanguages}
                    isTeamAdmin={isTeamAdmin}
                    isInUserGroup={isInUserGroup}
                    isSearchEnabled={isSearchEnabled}
                />
            </AppProviders>
        );
    }
}

customElements.define('project-list', ProjectListWebComponent);
