import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const UserGroupPermissions = lazy(() =>
    import('./UserGroupPermissions').then((module) => ({ default: module.UserGroupPermissions })),
);

class UserGroupPermissionsWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['group-id'];
    }

    render() {
        const groupIdStr = this.prop('group-id');
        if (!groupIdStr) {
            return null;
        }

        const groupId = Number(groupIdStr);
        if (Number.isNaN(groupId)) {
            return null;
        }

        return (
            <RootProviders>
                <UserGroupPermissions userGroupId={groupId} />
            </RootProviders>
        );
    }
}

customElements.define('user-group-permissions', UserGroupPermissionsWebComponent);
