import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const StatisticsPageAnalyticsBanner = lazy(() => import('./StatisticsPageAnalyticsBanner'));

class StatisticsPageAnalyticsBannerComponent extends ReactWebComponent {
    render() {
        return (
            <AppProviders>
                <StatisticsPageAnalyticsBanner />
            </AppProviders>
        );
    }
}

customElements.define('statistics-page-analytics-banner', StatisticsPageAnalyticsBannerComponent);
