import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));

const CommentsSidebar = lazy(() => import('./CommentsSidebar'));

class CommentsSidebarWebComponent extends ReactWebComponent {
    render() {
        return (
            <RootProviders>
                <CommentsSidebar />
            </RootProviders>
        );
    }
}

customElements.define('comments-sidebar', CommentsSidebarWebComponent);
