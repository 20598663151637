import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const SSOAuthErrorPage = lazy(() => import('./SSOAuthErrorPage'));

class SSOAuthErrorPageWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['summary', 'content'];
    }

    render() {
        const summary = this.prop('summary');
        const content = this.prop('content');

        return (
            <PageRoot>
                <SSOAuthErrorPage content={content} summary={summary} />
            </PageRoot>
        );
    }
}

customElements.define('sso-auth-error-page', SSOAuthErrorPageWebComponent);
