/**
 * Depending on whether the Twig template was rendered in BE or dynamically
 * on the frontend, it uses different encoding for JSON.
 * This function normalizes this encoding and returns parsed JSON object.
 */
export const parseTwigJson = <T>(jsonString: string): T => {
    try {
        return JSON.parse(jsonString);
    } catch (e) {
        const parsedJson = new DOMParser().parseFromString(jsonString, 'text/html').documentElement.textContent;

        return JSON.parse(parsedJson ?? '');
    }
};
