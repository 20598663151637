import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const UserGroupsPermissionsWarning = lazy(() =>
    import('./UserGroupMembers').then((module) => ({ default: module.UserGroupsPermissionsWarning })),
);

class UserGroupPermissionsWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['group-id', 'user-id'];
    }

    render() {
        const userId = Number(this.prop('user-id'));
        const groupId = Number(this.prop('group-id'));

        if (Number.isNaN(userId) || Number.isNaN(groupId)) {
            return null;
        }

        return (
            <RootProviders>
                <UserGroupsPermissionsWarning userGroupId={groupId} userId={userId} />
            </RootProviders>
        );
    }
}

customElements.define('user-group-members', UserGroupPermissionsWebComponent);
