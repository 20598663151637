import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const ScreenshotsUploader = lazy(() => import('.'));

class ScreenshotsUploaderComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['compact-view', 'ocr-enabled'];
    }

    render() {
        const compactView = this.getAttribute('compact-view') === 'true';
        const ocrEnabled = this.getAttribute('ocr-enabled') === 'true';

        return (
            <AppProviders>
                <ScreenshotsUploader
                    compactView={compactView}
                    ocrEnabled={ocrEnabled}
                    onChange={(checked) => {
                        const event = new CustomEvent('changeocr', { detail: checked });
                        this.dispatchEvent(event);
                    }}
                    onFileSelect={() => {
                        const event = new Event('fileselect');
                        this.dispatchEvent(event);
                    }}
                />
            </AppProviders>
        );
    }
}

customElements.define('screenshot-upload', ScreenshotsUploaderComponent);
