import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const BranchCreateModal = lazy(() => import('.'));

class BranchCreateModalComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['project-id', 'opened'];
    }

    render() {
        const projectId = this.prop('project-id');
        const opened = this.prop('opened') === 'true';

        return (
            <RootProviders>
                {opened && (
                    <BranchCreateModal
                        projectId={projectId}
                        opened={opened}
                        onClose={() => {
                            this.dispatchEvent(new Event('close'));
                        }}
                        onBranchCreated={(branchName) => {
                            this.dispatchEvent(new CustomEvent('branchcreated', { detail: branchName }));
                        }}
                    />
                )}
            </RootProviders>
        );
    }
}

customElements.define('branch-create-modal', BranchCreateModalComponent);
