import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const WorkflowsPage = lazy(() => import('.'));

class WebComponent extends ReactWebComponent {
    render() {
        return (
            <PageRoot>
                <WorkflowsPage />
            </PageRoot>
        );
    }
}

customElements.define('workflows-page', WebComponent);
