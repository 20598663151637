import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const TeamBillingHistory = lazy(() => import('./TeamBillingHistory'));

class TeamBillingHistoryWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [];
    }

    render() {
        return (
            <PageRoot>
                <TeamBillingHistory />
            </PageRoot>
        );
    }
}

customElements.define('team-billing-history', TeamBillingHistoryWebComponent);
