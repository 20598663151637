import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const ScreenshotsFooter = lazy(() => import('.'));

class ScreenshotsFooterComponent extends ReactWebComponent {
    render() {
        return (
            <AppProviders>
                <ScreenshotsFooter />
            </AppProviders>
        );
    }
}

customElements.define('screenshots-footer', ScreenshotsFooterComponent);
