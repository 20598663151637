import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const OtaTrafficUsageWidget = lazy(() => import('.'));

class OtaTrafficUsageWidgetComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['project-id'];
    }

    render() {
        const projectId = this.prop('project-id');

        return (
            <AppProviders>
                <OtaTrafficUsageWidget projectId={projectId} legacy />
            </AppProviders>
        );
    }
}

customElements.define('ota-traffic-usage-widget', OtaTrafficUsageWidgetComponent);
