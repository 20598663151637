import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const TeamCardExpiryBanner = lazy(() => import('.'));

const RootProviders = lazy(() => import('@app/components/AppProviders'));

class TeamCardExpiryBannerWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['days-until-expiry'];
    }

    render() {
        const daysUntilExpiry = this.prop('days-until-expiry');

        return (
            <RootProviders>
                <TeamCardExpiryBanner daysUntilExpiry={Number(daysUntilExpiry)} />
            </RootProviders>
        );
    }
}

customElements.define('team-card-expiry-banner', TeamCardExpiryBannerWebComponent);
