import type { ProjectLanguage, RawProjectLanguage } from '../types';

const mapProjectLanguages = (rawProjectLanguages: RawProjectLanguage | null): ProjectLanguage[] => {
    if (!rawProjectLanguages) {
        return [];
    }

    return Object.values(rawProjectLanguages).map((language) => ({
        title: language.name,
        id: Number(language.lang_id),
        ccIso: language.cc_iso,
        langIso: language.lang_iso,
        isDefault: language.is_default === '1',
        isHidden: language.is_hidden === '1',
        isLqaiSupportedLang: language.is_lqai_supported_lang,
        aiLanguageInBeta: language.ai_language_in_beta,
    }));
};

export default mapProjectLanguages;
