import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const TeamSettingsHeader = lazy(() => import('./TeamSettingsHeader'));

class TeamSettingsHeaderWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['team-id', 'team-name', 'logo-url'];
    }

    render() {
        const teamId = this.prop('team-id');
        const teamName = this.prop('team-name');
        const logoUrl = this.prop('logo-url');

        return (
            <RootProviders>
                <TeamSettingsHeader teamId={teamId} teamName={teamName} logoUrl={logoUrl} />
            </RootProviders>
        );
    }
}

customElements.define('team-settings-header', TeamSettingsHeaderWebComponent);
