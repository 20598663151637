import isMac from '../isMac';

const replacePlatformSpecific = (shortcut: string) => {
    if (!shortcut) return '';

    return isMac() ? shortcut.replace('Cmd', '⌘').replace('Opt', '⌥').replaceAll('+', '') : shortcut;
};

const normalizeShortcut = (shortcut: string | string[]): string =>
    Array.isArray(shortcut) ? shortcut.map(replacePlatformSpecific).join(' / ') : replacePlatformSpecific(shortcut);

export default normalizeShortcut;
