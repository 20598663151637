import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const EditorStats = lazy(() => import('.'));

class EditorStatsWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['target-language-id'];
    }

    render() {
        const targetLanguageId = this.prop('target-language-id') || null;

        return (
            <RootProviders>
                <EditorStats targetLanguageId={targetLanguageId} />
            </RootProviders>
        );
    }
}

customElements.define('editor-stats', EditorStatsWebComponent);
