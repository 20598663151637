import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { TranslationMemoryProps } from './TranslationMemory';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const EditorTranslationMemory = lazy(() => import('.'));

class TranslationMemoryComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['translation-memory-data', 'project-id', 'trans-id'];
    }

    render() {
        const { data, base } = this.jsonProp<TranslationMemoryProps>('translation-memory-data') ?? {
            data: [],
            base: '',
        };
        const projectId = this.prop('project-id');
        const transId = this.prop('trans-id');

        return (
            <AppProviders>
                <EditorTranslationMemory
                    projectId={projectId}
                    segmentId={transId}
                    data={data}
                    base={base}
                    onDeleted={(translationMemoryEntryId) => {
                        const event = new CustomEvent('ontxmentrydeleted', { detail: translationMemoryEntryId });
                        this.dispatchEvent(event);
                    }}
                    onTranslationMemoryDestroy={() => {
                        this.dispatchEvent(new Event('onremoveeventlisteners'));
                    }}
                />
            </AppProviders>
        );
    }
}

customElements.define('editor-translation-memory', TranslationMemoryComponent);
