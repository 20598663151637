import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const SuspendedPage = lazy(() => import('.'));

class SuspendedPageComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['reason'];
    }

    render() {
        const reason = this.prop('reason');

        return (
            <PageRoot>
                <SuspendedPage reason={reason} />
            </PageRoot>
        );
    }
}

customElements.define('suspended-page', SuspendedPageComponent);
