import { lazy } from 'react';

import type { GetTermsResponse } from '@app/api/glossary';
import { ReactWebComponent } from '@app/utils/web-components';

import type { ProjectLanguage } from './types';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const GlossaryPage = lazy(() => import('./components/GlossaryPage'));

class GlossaryPageComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['terms', 'project-id', 'project-languages', 'shared-glossary', 'glossary-enabled'];
    }

    render() {
        const terms = this.jsonProp<GetTermsResponse>('terms');
        const projectId = this.prop('project-id');
        const projectLanguages = this.jsonProp<ProjectLanguage[]>('project-languages') ?? [];
        const usesSharedGlossary = this.prop('shared-glossary') === 'true';
        const glossaryEnabled = this.prop('glossary-enabled') === 'true';

        if (!terms) {
            return null;
        }

        return (
            <PageRoot>
                <GlossaryPage
                    initialTerms={terms}
                    projectId={projectId}
                    projectLanguages={projectLanguages}
                    usesSharedGlossary={usesSharedGlossary}
                    glossaryEnabled={glossaryEnabled}
                />
            </PageRoot>
        );
    }
}

customElements.define('glossary-page', GlossaryPageComponent);
