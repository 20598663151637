import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { CustomTranslationStatus, MassTaskData, RawParentTask, RawProjectLanguage } from './types';
import findDefaultSourceLanguageId from './utils/findDefaultSourceLanguage';
import mapParentTasks from './utils/mapParentTasks';
import mapProjectLanguages from './utils/mapProjectLanguages';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const NewTask = lazy(() => import('.'));

class NewTaskPageWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'project-languages',
            'ctsEnabled',
            'ctsMultiEnabled',
            'availableCts',
            'availableParentTasks',
            'isMassTask',
            'translatableKeysCount',
            'untranslatableKeysCount',
            'archivedKeysCount',
            'defaultLocksForContributors',
            'isReviewingEnabled',
            'teamAiWordsUsage',
            'teamAiWordsLimit',
        ];
    }

    render() {
        const projectLanguages = this.jsonProp<RawProjectLanguage>('project-languages');
        const ctsEnabled = this.jsonProp<boolean>('ctsEnabled') ?? false;
        const ctsMultiEnabled = this.jsonProp('ctsMultiEnabled') === '1';
        const availableCts = this.jsonProp<CustomTranslationStatus[]>('availableCts') || [];
        const availableParentTasks = this.jsonProp<RawParentTask[]>('availableParentTasks') || [];
        const isMassTask = this.jsonProp<number>('isMassTask') === 1;
        const translatableKeysCount = this.jsonProp<number>('translatableKeysCount') ?? 0;
        const untranslatableKeysCount = this.jsonProp<number>('untranslatableKeysCount') ?? 0;
        const archivedKeysCount = this.jsonProp<number>('archivedKeysCount') ?? 0;
        const defaultLocksForContributors = this.jsonProp<number>('defaultLocksForContributors') === 1;
        const isReviewingEnabled = this.jsonProp<number>('isReviewingEnabled') === 1;
        const teamAiWordsUsage = this.jsonProp<number>('teamAiWordsUsage') || 0;
        const teamAiWordsLimit = this.jsonProp<number>('teamAiWordsLimit') || 0;

        const massTaskData: MassTaskData = {
            enabled: isMassTask,
            defaultSourceLanguageId: findDefaultSourceLanguageId(projectLanguages),
            translatableKeysCount,
            untranslatableKeysCount,
            archivedKeysCount,
        };

        const mappedProjectLanguages = mapProjectLanguages(projectLanguages);
        const mappedParentTasks = mapParentTasks(availableParentTasks);

        return (
            <PageRoot>
                <NewTask
                    reviewingEnabled={isReviewingEnabled}
                    ctsEnabled={ctsEnabled}
                    ctsMultiEnabled={ctsMultiEnabled}
                    availableCts={availableCts}
                    projectLanguages={mappedProjectLanguages}
                    availableParentTasks={mappedParentTasks}
                    massTaskData={massTaskData}
                    forceLockTranslations={defaultLocksForContributors}
                    teamAiWordsUsage={teamAiWordsUsage}
                    teamAiWordsLimit={teamAiWordsLimit}
                />
            </PageRoot>
        );
    }
}

customElements.define('new-tasks-create', NewTaskPageWebComponent);
