import { lazy } from 'react';

import { getCookie } from '@app/utils/request';
import { ReactWebComponent } from '@app/utils/web-components';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const SSOLoginPage = lazy(() => import('./SSOLoginPage'));

class SSOLoginPageWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['team', 'token', 'domain'];
    }

    render() {
        const team = this.prop('team');
        const token = this.prop('token');
        const domain = this.prop('domain');
        const csrfToken = getCookie('csrf_token');

        return (
            <PageRoot>
                <SSOLoginPage team={team} token={token} domain={domain} csrfToken={csrfToken} />
            </PageRoot>
        );
    }
}

customElements.define('sso-login-page', SSOLoginPageWebComponent);
