import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const TeamBillingDetails = lazy(() => import('./TeamBillingDetails'));

class TeamBillingDetailsWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [];
    }

    render() {
        return (
            <RootProviders>
                <TeamBillingDetails />
            </RootProviders>
        );
    }
}

customElements.define('team-billing-details', TeamBillingDetailsWebComponent);
