import { lazy } from 'react';

import FeatureFlag from '@app/components/FeatureFlag';
import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const WorkflowsConflictBannerContainer = lazy(() => import('.'));

class WorkflowsConflictBannerWebComponent extends ReactWebComponent {
    render() {
        return (
            <RootProviders>
                <FeatureFlag flag="metroWorkflowsManagement">
                    <WorkflowsConflictBannerContainer />
                </FeatureFlag>
            </RootProviders>
        );
    }
}

customElements.define('workflows-conflict-banner', WorkflowsConflictBannerWebComponent);
