import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { AppType } from './types';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const AppManagement = lazy(() => import('./Management'));

class AppsManagementComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['project-id', 'app-slug', 'type'];
    }

    render() {
        const projectId = this.prop('project-id');
        const slug = this.prop('app-slug');
        const type: AppType = this.prop('type') as AppType;

        return (
            <RootProviders>
                <AppManagement projectId={projectId} slug={slug} type={type} />
            </RootProviders>
        );
    }
}

customElements.define('apps-management', AppsManagementComponent);
