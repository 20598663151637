import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const EditorPageInitiator = lazy(() => import('.'));
type Translation = {
    [key: string]: { trans_id: string }[];
};
interface ProjectKeys {
    [key: string]: { hidden: string; translations: Translation };
}

class TranslationPanelComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['projectId', 'keys', 'keysPerPage'];
    }

    render() {
        const projectId = this.getAttribute('projectId') || '';
        const projectKeys = this.jsonProp<ProjectKeys>('keys') || {};
        const projectKeyIds = Object.keys(projectKeys);
        const keysPerPage = Math.min(Number(this.getAttribute('keysPerPage')), projectKeyIds.length);

        const getTranslationIds = () => {
            const visibleTranslationIds: string[] = [];

            const visibleKeys = projectKeyIds.filter((key) => projectKeys[key].hidden === '0').slice(0, keysPerPage);
            visibleKeys.forEach((visibleKeyId) => {
                const { translations } = projectKeys[visibleKeyId];
                const translationIds = Object.keys(translations);
                visibleTranslationIds.push(
                    ...translationIds.map((translationId) => translations[translationId][0].trans_id),
                );
            });

            return visibleTranslationIds;
        };

        const lockedTranslationIds = getTranslationIds();
        if (!lockedTranslationIds.length) {
            return null;
        }

        return (
            <AppProviders>
                <EditorPageInitiator projectId={projectId} translationIds={lockedTranslationIds} />
            </AppProviders>
        );
    }
}

customElements.define('editor-page-initiator', TranslationPanelComponent);
