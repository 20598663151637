import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const UploadEmpty = lazy(() => import('.'));

class UploadEmptyComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [];
    }

    render() {
        return (
            <AppProviders>
                <UploadEmpty />
            </AppProviders>
        );
    }
}

customElements.define('upload-empty', UploadEmptyComponent);
