import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const ClipboardModalContainer = lazy(() => import('.'));

class ClipboardModalComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['key-id', 'key-name', 'link', 'key-id-reference'];
    }

    render() {
        const keyId = this.getAttribute('key-id');
        const keyName = this.getAttribute('key-name');
        const link = this.getAttribute('link');
        const keyIdReference = this.getAttribute('key-id-reference');

        if (keyId === null || keyName === null || link === null || keyIdReference === null) {
            return null;
        }

        return (
            <RootProviders>
                <ClipboardModalContainer
                    keyId={keyId}
                    keyName={keyName}
                    link={link}
                    keyIdReference={keyIdReference}
                    onClose={() => {
                        const event = new Event('onclose');
                        this.dispatchEvent(event);
                    }}
                    isOpen
                />
            </RootProviders>
        );
    }
}

customElements.define('clipboard-modal', ClipboardModalComponent);
