import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { View } from '../../types/preview';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const OpenPreview = lazy(() => import('.'));

class OpenPreviewWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['target-lang-id', 'file-name', 'view'];
    }

    render() {
        const fileName = this.prop('file-name');
        const targetLanguageId = this.prop('target-lang-id');
        const view = this.prop('view') as View;

        return (
            <RootProviders>
                <OpenPreview fileName={fileName} targetLanguageId={targetLanguageId} view={view} />
            </RootProviders>
        );
    }
}

customElements.define('open-preview', OpenPreviewWebComponent);
