import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const NewVersionNotification = lazy(() => import('.'));

class NewVersionNotificationWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['lokalise-version'];
    }

    render() {
        const lokaliseVersion = this.getAttribute('lokalise-version');

        return (
            <RootProviders>
                <NewVersionNotification lokaliseVersion={lokaliseVersion} />
            </RootProviders>
        );
    }
}

customElements.define('new-version-notification', NewVersionNotificationWebComponent);
