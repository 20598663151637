import type { AxiosPromise, AxiosRequestConfig } from 'axios';
import axios from 'axios';

interface RequestInterface {
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    url: string;
    contentType?: string;
    data?: unknown;
    params?: unknown;
    responseType?: 'json' | 'arraybuffer' | 'blob' | 'document' | 'text' | 'stream';
    headers?: AxiosRequestConfig['headers'];
}

export type Response<T = unknown> = AxiosPromise<T>;

export const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) {
        return parts.pop()?.split(';').shift() ?? null;
    }

    return null;
};

const GetCsrfHeader = (method: string) => {
    if (!/^(GET|HEAD|OPTIONS)$/.test(method)) {
        const CsrfToken = getCookie('csrf_token');

        if (CsrfToken) {
            return {
                'X-CSRF-Token': CsrfToken,
            };
        }
    }

    return {};
};

const request = <T = unknown>({
    method,
    url,
    data,
    contentType = 'application/json',
    params,
    responseType = 'json',
    headers,
}: RequestInterface): Response<T> => {
    const formHeaders = data instanceof FormData ? {} : { 'Content-Type': contentType };

    return axios(url, {
        method,
        headers: {
            'x-requested-with': 'XMLHttpRequest',
            ...GetCsrfHeader(method),
            ...formHeaders,
            ...headers,
        },
        data,
        params,
        responseType,
    });
};
export default request;
