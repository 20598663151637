import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const ShortcutsHelpModalContainer = lazy(() => import('.'));

class ShortcutsHelpComponent extends ReactWebComponent {
    render() {
        return (
            <RootProviders>
                <ShortcutsHelpModalContainer />
            </RootProviders>
        );
    }
}

customElements.define('shortcuts-help', ShortcutsHelpComponent);
