import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const ShowOfflineTool = lazy(() => import('.'));

class ShowOfflineToolWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['is-open', 'languages', 'keys'];
    }

    render() {
        const offlineToolActive = !!this.jsonProp<boolean>('offline-tool-active');

        return (
            <RootProviders>
                <ShowOfflineTool offlineToolActive={offlineToolActive} />
            </RootProviders>
        );
    }
}

customElements.define('show-offline-tool', ShowOfflineToolWebComponent);
