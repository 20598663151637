import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { ProjectSettingType } from './types';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const QACheck = lazy(() => import('.'));

class QAChecksComponent extends ReactWebComponent {
    static getObservedAttributes() {
        return ['project-id', 'qa-project-settings'];
    }

    render() {
        const projectId = this.jsonProp<string>('project-id');
        const qaProjectSettings = this.jsonProp<ProjectSettingType[]>('qa-project-settings');

        return (
            projectId && (
                <AppProviders>
                    <QACheck projectID={projectId} projectSettings={qaProjectSettings} />
                </AppProviders>
            )
        );
    }
}

customElements.define('settings-qa-checks', QAChecksComponent);
