import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const SSOIndexPage = lazy(() => import('./SSOIndexPage'));

class SSOIndexPageWebComponent extends ReactWebComponent {
    render() {
        return (
            <PageRoot>
                <SSOIndexPage />
            </PageRoot>
        );
    }
}

customElements.define('sso-index-page', SSOIndexPageWebComponent);
