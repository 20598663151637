import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const TeamSettingsPage = lazy(() => import('.'));

class TeamSettingsPageComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'seats-taken',
            'seats-limit',
            'grace-period-seats',
            'invited-seats',
            'is-provider-alpha',
            'extra-seats-allowed',
            'show-team-delete-modal',
        ];
    }

    render() {
        const seatsTaken = Number(this.prop('seats-taken'));
        const seatsLimit = Number(this.prop('seats-limit'));
        const gracePeriodSeats = Number(this.prop('grace-period-seats'));
        const invitedSeats = Number(this.prop('invited-seats'));
        const showTeamDeleteModal = this.prop('show-team-delete-modal') === '1';
        const isExtraSeatsAllowed = this.jsonProp('extra-seats-allowed') === 1;
        const isProviderAlpha = this.jsonProp('is-provider-alpha') === 1;

        return (
            <PageRoot>
                <TeamSettingsPage
                    isProviderAlpha={isProviderAlpha}
                    isExtraSeatsAllowed={isExtraSeatsAllowed}
                    invitedSeats={invitedSeats}
                    seatsTaken={seatsTaken}
                    seatsLimit={seatsLimit}
                    gracePeriodSeats={gracePeriodSeats}
                    showTeamDeleteModal={showTeamDeleteModal}
                />
            </PageRoot>
        );
    }
}

customElements.define('team-settings-page', TeamSettingsPageComponent);
