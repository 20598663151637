import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { Feature, PrivacyMode } from './types';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const AdvancedSecurityPage = lazy(() => import('./AdvancedSecurity'));

const defaultFeature: Feature = {
    enabled: false,
    available: false,
};

class AdvancedSecurityPageWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['two-factor', 'sso', 'secure-password', 'join-requests-count', 'team-privacy-mode'];
    }

    render() {
        const twoFactor = this.jsonProp<Feature>('two-factor') ?? defaultFeature;
        const sso = this.jsonProp<Feature>('sso') ?? defaultFeature;
        const securePassword = this.jsonProp<Feature>('secure-password') ?? defaultFeature;
        const joinRequestsCount = this.prop('join-requests-count');
        const teamPrivacyMode = this.prop('team-privacy-mode') as PrivacyMode;

        return (
            <PageRoot>
                <AdvancedSecurityPage
                    twoFactor={twoFactor}
                    sso={sso}
                    securePassword={securePassword}
                    privacyMode={teamPrivacyMode}
                    joinRequestsCount={parseInt(joinRequestsCount, 10)}
                />
            </PageRoot>
        );
    }
}

customElements.define('advanced-security-page', AdvancedSecurityPageWebComponent);
