import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const UploadStyleGuideWizard = lazy(() => import('./components/UploadStyleGuideWizard/UploadStyleGuideWizard'));
const StyleGuideContextProvider = lazy(() => import('./StyleGuideContextProvider'));
const PageRoot = lazy(() => import('@app/components/PageRoot'));
const StyleGuidePage = lazy(() => import('.'));

class StyleGuideWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['teamId'];
    }

    render() {
        // Force casting to avoid nullable type
        // if the controller  failed to get teamId the page will not load anyhow
        const teamId = this.jsonProp<number>('teamId') as number;

        return (
            <PageRoot>
                <StyleGuideContextProvider teamId={teamId}>
                    <StyleGuidePage />
                    <UploadStyleGuideWizard />
                </StyleGuideContextProvider>
            </PageRoot>
        );
    }
}

customElements.define('style-guide-page', StyleGuideWebComponent);
