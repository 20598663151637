import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));

const ProjectSnapshotsContainer = lazy(() => import('.'));

class ProjectSnapshotsView extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'project-id',
            'automatic-upload',
            'automatic-update',
            'automatic-bulk-actions',
            'automatic-update-enabled',
            'automatic-upload-enabled',
            'automatic-bulk-actions-enabled',
            'undo-bulk-actions-enabled',
        ];
    }

    render() {
        const projectId = this.prop('project-id');
        const automaticUpdate = this.prop('automatic-update');
        const automaticUpload = this.prop('automatic-upload');
        const automaticBulkActions = this.prop('automatic-bulk-actions');
        const automaticUpdateEnabled = this.prop('automatic-update-enabled');
        const automaticUploadEnabled = this.prop('automatic-upload-enabled');
        const automaticBulkActionsEnabled = this.prop('automatic-bulk-actions-enabled');
        const undoBulkActionsEnabled = this.prop('undo-bulk-actions-enabled');

        return (
            <RootProviders>
                <ProjectSnapshotsContainer
                    projectId={projectId}
                    automaticUploadInit={!!parseInt(automaticUpload, 10)}
                    automaticUpdateInit={!!parseInt(automaticUpdate, 10)}
                    automaticBulkActionsInit={!!parseInt(automaticBulkActions, 10)}
                    automaticUpdateEnabled={!!parseInt(automaticUpdateEnabled, 10)}
                    automaticUploadEnabled={!!parseInt(automaticUploadEnabled, 10)}
                    automaticBulkActionsEnabled={!!parseInt(automaticBulkActionsEnabled, 10)}
                    undoBulkActionsEnabled={!!parseInt(undoBulkActionsEnabled, 10)}
                />
            </RootProviders>
        );
    }
}

customElements.define('project-snapshots', ProjectSnapshotsView);
