import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const KeyListLoading = lazy(() => import('./KeyListLoading'));

class KeyListLoadingComponent extends ReactWebComponent {
    render() {
        return (
            <AppProviders>
                <KeyListLoading />
            </AppProviders>
        );
    }
}

customElements.define('key-list-loading', KeyListLoadingComponent);
