import type { ReactNode } from 'react';

import useIsDarkMode from '@app/hooks/useIsDarkMode';

import styles from './styles.module.css';

export const PageContainer = ({ children }: { children: ReactNode }) => {
    const isDarkMode = useIsDarkMode();
    const className = isDarkMode ? `${styles.pageContainer} ${styles.darkBackground}` : styles.pageContainer;

    return <div className={className}>{children}</div>;
};
