import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { DownloadLanguage, TwigContext } from './types';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const Files = lazy(() => import('.'));

class FilesComponent extends ReactWebComponent {
    static getObservedAttributes() {
        return ['project-id', 'downloadLanguages', 'team-id', 'user-id'];
    }

    render() {
        const projectId = this.prop('project-id');
        const teamId = this.prop('team-id');
        const userId = this.prop('user-id');
        const downloadLanguages = this.jsonProp('downloadLanguages') as DownloadLanguage[];

        const twigContext = {
            projectId,
            teamId,
            userId,
            downloadLanguages,
        } as TwigContext;

        if (!projectId || !teamId || !userId) {
            return null;
        }

        return (
            <AppProviders twigContext={twigContext}>
                <Files projectId={projectId} teamId={teamId} userId={userId} />
            </AppProviders>
        );
    }
}

customElements.define('file-list', FilesComponent);
