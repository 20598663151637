import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { ResetPageProps } from './ResetPage';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const ResetPage = lazy(() => import('.'));

class ResetPageWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['token', 'user', 'welcome', 'team-invite', 'landing-url'];
    }

    render() {
        const token = this.prop('token');
        const welcome = !!this.prop('welcome');
        const teamInvite = !!this.prop('team-invite');
        const user: ResetPageProps['user'] = this.jsonProp('user') || { fullname: '', email: '' };
        const landingUrl = this.prop('landing-url');

        return (
            <PageRoot>
                <ResetPage
                    user={user}
                    welcome={welcome}
                    token={token}
                    teamInvite={teamInvite}
                    landingUrl={landingUrl}
                />
            </PageRoot>
        );
    }
}

customElements.define('reset-page', ResetPageWebComponent);
