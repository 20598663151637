import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { OauthHeaderProps } from '../../components';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const TwoFactorPage = lazy(() => import('.'));

class TwoFactorPageWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['text', 'from-uri', 'logout-href', 'oauth-detail'];
    }

    render() {
        const text = this.jsonProp<Record<string, string>>('text') || {};
        const oauthDetail = this.jsonProp<OauthHeaderProps | undefined>('oauth-detail') || undefined;
        const fromUri = this.prop('from-uri');
        const logoutHref = this.prop('logout-href');

        return (
            <PageRoot>
                <TwoFactorPage text={text} fromUri={fromUri} logoutHref={logoutHref} oauthDetail={oauthDetail} />
            </PageRoot>
        );
    }
}

customElements.define('two-factor-page', TwoFactorPageWebComponent);
