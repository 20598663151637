import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const DownloadFile = lazy(() => import('.'));

class DownloadFileWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['disabled', 'is-admin', 'project-id', 'file-name', 'target-language-id'];
    }

    render() {
        const disabled = this.prop('disabled') === '1';
        const admin = this.prop('is-admin') === '1';
        const projectId = this.prop('project-id') ?? '';
        const fileName = this.prop('file-name') ?? '';
        const targetLanguageId = Number(this.prop('target-language-id')) ?? 0;

        if (targetLanguageId === 0 || projectId === '' || fileName === '') {
            return null;
        }

        return (
            <RootProviders>
                <DownloadFile
                    disabled={disabled}
                    fileName={fileName}
                    admin={admin}
                    projectId={projectId}
                    targetLanguageId={targetLanguageId}
                />
            </RootProviders>
        );
    }
}

customElements.define('download-file', DownloadFileWebComponent);
