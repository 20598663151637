import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const QuickStartButton = lazy(() => import('.'));

class QuickStartButtonWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['show-quick-start-button', 'is-end-of-trial-active', 'is-team-suspended'];
    }

    render() {
        const showQuickStartButton = this.prop('show-quick-start-button') === 'true';
        const isEndOfTrialActive = this.prop('is-end-of-trial-active') === 'true';
        const isTeamSuspended = this.prop('is-team-suspended') === 'true';

        return (
            <RootProviders>
                <QuickStartButton
                    showQuickStartButton={showQuickStartButton}
                    isEndOfTrialActive={isEndOfTrialActive}
                    isTeamSuspended={isTeamSuspended}
                />
            </RootProviders>
        );
    }
}

customElements.define('quick-start-button', QuickStartButtonWebComponent);
