import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const ProjectHeader = lazy(() => import('.'));

class ProjectHeaderComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['active-item', 'sticky'];
    }

    render() {
        const activeItem = this.prop('active-item');
        const sticky = this.prop('sticky') === 'true';

        return (
            <RootProviders>
                <ProjectHeader activeItem={activeItem} sticky={sticky} />
            </RootProviders>
        );
    }
}

customElements.define('project-header', ProjectHeaderComponent);
