import { lazy } from 'react';

import type { UpcomingSubscriptionResponse } from '@app/api/team';
import { ReactWebComponent } from '@app/utils/web-components';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const PricingBreakdown = lazy(() => import('.'));

class PricingBreakdownWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['upcoming-invoice'];
    }

    render() {
        const upcomingInvoice = this.jsonProp<UpcomingSubscriptionResponse>('upcoming-invoice');

        if (!upcomingInvoice) {
            return null;
        }

        return (
            <PageRoot>
                <PricingBreakdown upcomingInvoice={upcomingInvoice} />
            </PageRoot>
        );
    }
}

customElements.define('pricing-breakdown', PricingBreakdownWebComponent);
