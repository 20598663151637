import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type {
    DataRange,
    EnabledTriggers,
    ExportEmptyOptions,
    ExportNullAsOptions,
    ExportSortOptions,
    FileFormatMapping,
    FileFormats,
    IntegrationsConfig,
    JavaPropertiesEncodingOptions,
    JavaPropertiesSeparatorOptions,
    ModuleProps,
    QaIssues,
    Repositories,
} from './types';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const Download = lazy(() => import('.'));

class DownloadWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'over-limit',
            'can-modify-billing',
            'data',
            'file-formats',
            'data-range',
            'order-by-keys',
            'java-properties-separator-options',
            'java-properties-encoding-options',
            'enabled-triggers',
            'repositories',
            'project-branch-name',
            'file-format-mapping',
            'qa-issues',
        ];
    }

    render() {
        const overLimit = this.prop('over-limit') === 'true';
        const canModifyBilling = this.prop('can-modify-billing') === '1';
        const props = this.jsonProp<ModuleProps>('data');
        const fileFormats = this.jsonProp<FileFormats>('file-formats');
        const dataRange = this.jsonProp<DataRange>('data-range');
        const exportSortOptions = this.jsonProp<ExportSortOptions>('export-sort-options');
        const exportEmptyOptions = this.jsonProp<ExportEmptyOptions>('export-empty-options');
        const exportNullAsOptions = this.jsonProp<ExportNullAsOptions>('export-null-as-options');
        const javaPropertiesSeparatorOptions = this.jsonProp<JavaPropertiesSeparatorOptions>(
            'java-properties-separator-options',
        );
        const javaPropertiesEncodingOptions = this.jsonProp<JavaPropertiesEncodingOptions>(
            'java-properties-encoding-options',
        );
        const enabledTriggers = this.jsonProp<EnabledTriggers>('enabled-triggers');
        const integrationsConfig = this.jsonProp<IntegrationsConfig>('integrations-config');
        const repositories = this.jsonProp<Repositories>('repositories');
        const projectBranchName = this.prop('project-branch-name');
        const fileFormatMapping = this.jsonProp<FileFormatMapping>('file-format-mapping');
        const qaIssues = this.jsonProp<QaIssues>('qa-issues');

        if (
            !(
                props &&
                fileFormats &&
                dataRange &&
                exportSortOptions &&
                exportEmptyOptions &&
                exportNullAsOptions &&
                javaPropertiesSeparatorOptions &&
                javaPropertiesEncodingOptions &&
                enabledTriggers &&
                repositories &&
                fileFormatMapping &&
                integrationsConfig &&
                qaIssues
            )
        ) {
            throw new Error('DownloadWebComponent has missing props');
        }

        return (
            <PageRoot>
                <Download
                    overLimit={overLimit}
                    canModifyBilling={canModifyBilling}
                    props={props}
                    fileFormats={fileFormats}
                    dataRange={dataRange}
                    exportSortOptions={exportSortOptions}
                    exportEmptyOptions={exportEmptyOptions}
                    exportNullAsOptions={exportNullAsOptions}
                    javaPropertiesSeparatorOptions={javaPropertiesSeparatorOptions}
                    javaPropertiesEncodingOptions={javaPropertiesEncodingOptions}
                    enabledTriggers={enabledTriggers}
                    integrationsConfig={integrationsConfig}
                    repositories={repositories}
                    projectBranchName={projectBranchName === '' ? null : projectBranchName}
                    fileFormatMapping={fileFormatMapping}
                    qaIssues={qaIssues}
                />
            </PageRoot>
        );
    }
}

customElements.define('download-module', DownloadWebComponent);
