import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const ReviewCenterInviteModal = lazy(() => import('./ReviewCenterInviteModal'));

class ReviewCenterInviteModalWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['is-open', 'languages', 'keys'];
    }

    render() {
        const isOpen = !!this.jsonProp<boolean>('is-open');
        const languages = this.jsonProp<string[]>('languages');
        const keys = this.jsonProp<string[]>('keys');

        if (!isOpen || languages === null || keys === null) {
            return null;
        }

        const languagesToNumber = languages.map((value) => Number(value));
        const keysToNumber = keys.map((value) => Number(value));

        const onClose = () => {
            this.setAttribute('is-open', 'false');
            this.setAttribute('languages', 'null');
            this.setAttribute('keys', 'null');
        };

        const onInvitationSent = () => {
            this.dispatchEvent(new CustomEvent('review-center-invitation-sent'));
        };

        return (
            <RootProviders>
                <ReviewCenterInviteModal
                    isOpen={isOpen}
                    languages={languagesToNumber}
                    keys={keysToNumber}
                    onInvitationsSent={onInvitationSent}
                    onClose={onClose}
                />
            </RootProviders>
        );
    }
}

customElements.define('review-center-invite-modal', ReviewCenterInviteModalWebComponent);
