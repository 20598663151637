import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const UsageOverviewNewTag = lazy(() => import('./UsageOverviewNewTag'));

class UsageOverviewNewTagWebComponent extends ReactWebComponent {
    render() {
        return (
            <RootProviders>
                <UsageOverviewNewTag />
            </RootProviders>
        );
    }
}

customElements.define('usage-overview-new-tag', UsageOverviewNewTagWebComponent);
