import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const ToastContainer = lazy(() => import('@lokalise/louis').then((module) => ({ default: module.ToastContainer })));

(async () => {
    const { scheduleToast } = await import('.');
    window.scheduleToast = scheduleToast;
})();

(async () => {
    const { default: SetupToastEvents } = await import('./toastEvents');
    SetupToastEvents();
})();

class ToastContainerWebComponent extends ReactWebComponent {
    render() {
        return (
            <RootProviders>
                <ToastContainer />
            </RootProviders>
        );
    }
}

customElements.define('toast-container', ToastContainerWebComponent);
