import { lazy } from 'react';

import capitalize from '@app/utils/string/capitalize';
import { ReactWebComponent } from '@app/utils/web-components';

import type { LanguageMap } from './types';

const RootProviders = lazy(() => import('@app/components/AppProviders'));

const TranslationOrder = lazy(() => import('.'));

class TranslationOrderView extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'lang-to-id',
            'provider-id',
            'project-name',
            'file-names',
            'order-hash',
            'total-price',
            'total-words',
            'project-briefing',
            'eta',
            'source-lang-code',
            'target-lang-ids',
            'user-email',
        ];
    }

    render() {
        const langToId = this.jsonProp<LanguageMap>('lang-to-id');
        const providerId = parseInt(this.getAttribute('provider-id') ?? '', 10);
        const projectName = this.getAttribute('project-name');
        const fileNames = this.jsonProp<string[]>('file-names');
        const orderHash = this.getAttribute('order-hash');
        const totalPrice = parseFloat(this.getAttribute('total-price') || '');
        const totalWords = parseFloat(this.getAttribute('total-words') || '');
        const projectBriefing = this.getAttribute('project-briefing');
        const eta = this.getAttribute('eta')?.trim().replace('Delivery in ', '');
        const sourceLangCode = this.getAttribute('source-lang-code');
        const targetLangIds = this.getAttribute('target-lang-ids');
        const userEmail = this.prop('user-email');
        const selectedTier = parseInt(this.getAttribute('selected-tier') || '', 10);

        return (
            <RootProviders>
                <TranslationOrder
                    providerId={providerId ?? -1}
                    projectName={projectName ?? ''}
                    fileNames={fileNames ?? []}
                    orderHash={orderHash ?? ''}
                    totalPrice={totalPrice ?? 0}
                    totalWords={totalWords ?? 0}
                    projectBriefing={projectBriefing ?? ''}
                    eta={capitalize(eta ?? '')}
                    userEmail={userEmail}
                    sourceLangCode={sourceLangCode ?? ''}
                    targetLangIds={targetLangIds ?? ''}
                    langToId={langToId}
                    selectedTier={selectedTier}
                />
            </RootProviders>
        );
    }
}

customElements.define('translation-order', TranslationOrderView);
