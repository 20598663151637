import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { AllLanguagesLanguage } from '../../types';

const RootProviders = lazy(() => import('@app/components/AppProviders'));

const AddLanguageModal = lazy(() => import('../AddLanguageModal'));

type PartialProject = {
    projectId: string;
    projectLanguageIds: string[];
    projectBranchName: string;
};

class AddLanguageComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['project', 'all-languages', 'opened'];
    }

    render() {
        const isOpen = this.prop('opened') === 'true';
        const allLanguages = this.jsonProp<AllLanguagesLanguage[]>('all-languages') ?? [];
        const project = this.jsonProp<PartialProject>('project') ?? null;

        if (!isOpen || !project) return null;

        return (
            <RootProviders>
                <AddLanguageModal
                    projectLanguageIds={project.projectLanguageIds}
                    projectId={project.projectId}
                    projectBranchName={project.projectBranchName ?? undefined}
                    allLanguages={allLanguages}
                    onClose={() => {
                        this.dispatchEvent(new Event('onclose'));
                    }}
                />
            </RootProviders>
        );
    }
}

customElements.define('add-project-language-modal', AddLanguageComponent);
