import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const KeyLimitTopBannerAlert = lazy(() => import('.'));

const RootProviders = lazy(() => import('@app/components/AppProviders'));

class KeyLimitTopBannerAlertWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['plan-id', 'key-limit', 'key-count'];
    }

    render() {
        const planId = Number(this.prop('plan-id'));
        const keyLimit = Number(this.prop('key-limit'));
        const keyCount = Number(this.prop('key-count'));

        return (
            <RootProviders>
                <KeyLimitTopBannerAlert planId={planId} keyLimit={keyLimit} keyCount={keyCount} />
            </RootProviders>
        );
    }
}

customElements.define('key-limit-top-banner-alert', KeyLimitTopBannerAlertWebComponent);
