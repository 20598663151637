import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const Whoami = lazy(() => import('@app/components/Whoami'));

class ProjectIdentityComponent extends ReactWebComponent {
    render() {
        return (
            <PageRoot>
                <h1>Project Identity debug page</h1>
                <div>
                    <h2>Whoami</h2>
                    <Whoami />
                </div>
            </PageRoot>
        );
    }
}

customElements.define('project-identity', ProjectIdentityComponent);
