import { getReversedShortcuts, getUserPressedShortcut } from './helpers';
import normalizeShortcut from './normalize';
import type { ShortcutActions, ShortcutProfile } from './types';

export type ReversedShortcuts = Record<string, ShortcutActions>;

type Listener = (event?: KeyboardEvent) => void;

export type Listeners = Partial<Record<ShortcutActions, Listener[]>>;

class ShortcutRegistry {
    private profile: ShortcutProfile;

    private reversedShortcuts: ReversedShortcuts;

    private isInitialized = false;

    private listeners: Listeners = {};

    constructor(profile: ShortcutProfile) {
        this.profile = profile;
        this.reversedShortcuts = getReversedShortcuts(profile.shortcuts);
    }

    private initEventListener() {
        document.addEventListener('keydown', this.eventListener, { capture: true });
    }

    private eventListener = (event: KeyboardEvent) => {
        const userPressedShortcut = getUserPressedShortcut(event).join('+');

        const action = this.reversedShortcuts[userPressedShortcut];
        if (!action) {
            return;
        }

        this.executeListeners(action, event);
    };

    private executeListeners(action: ShortcutActions, event?: KeyboardEvent) {
        const listeners = this.listeners[action];
        listeners?.forEach((listener) => {
            if (event) {
                event.preventDefault();
                event.stopPropagation();
                listener(event);
            } else {
                listener();
            }
        });
    }

    executeAction(action: ShortcutActions) {
        this.executeListeners(action);
    }

    getProfileName() {
        return this.profile.profileName;
    }

    // eslint-disable-next-line class-methods-use-this
    normalizeKey(shortcut: string | string[]): string {
        return normalizeShortcut(shortcut);
    }

    changeProfile(profile: ShortcutProfile) {
        this.profile = profile;
        this.reversedShortcuts = getReversedShortcuts(profile.shortcuts);
    }

    addEventListener(action: ShortcutActions, callback: Listener) {
        // Restrict adding listeners without shortcut
        if (!this.profile.shortcuts[action].shortcut) {
            return;
        }

        if (this.listeners[action]) {
            this.listeners[action]?.push(callback);
        } else {
            this.listeners[action] = [callback];
        }

        if (!this.isInitialized) {
            this.initEventListener();
            this.isInitialized = true;
        }
    }

    removeEventListener(action: ShortcutActions, callback: Listener) {
        this.listeners[action] = this.listeners[action]?.filter((listener) => listener !== callback);
        // If there is no listeners, then we should stop listen for keydown listener.
        if (Object.values(this.listeners).every((listeners) => listeners.length === 0)) {
            document.removeEventListener('keydown', this.eventListener, { capture: true });
            this.isInitialized = false;
        }

        if (this.listeners[action]?.length === 0) {
            delete this.listeners[action];
        }
    }

    getActionLabel(action: ShortcutActions): string {
        const { shortcut } = this.profile.shortcuts[action];

        return shortcut ? normalizeShortcut(shortcut) : '';
    }

    getLabel(action: ShortcutActions): string {
        const { label } = this.profile.shortcuts[action];

        return label || '';
    }
}

export default ShortcutRegistry;
