import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const PageReloadAlert = lazy(() => import('.'));

class PageReloadAlertComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['is-editor-open'];
    }

    render() {
        const isEditorOpen = this.prop('is-editor-open') === 'true';

        return <PageReloadAlert condition={isEditorOpen} />;
    }
}

customElements.define('page-reload-alert', PageReloadAlertComponent);
