import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { ResetModal } from './LinkSentModal';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const LoginPage = lazy(() => import('.'));
const ToastContainer = lazy(() => import('@lokalise/louis').then((module) => ({ default: module.ToastContainer })));

class LoginPageWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['from-uri', 'email', 'project-id', 'lang', 'reset-modal', 'button-text', 'oauth-code'];
    }

    render() {
        const fromUri = this.prop('from-uri');
        const email = this.prop('email');
        const projectId = this.prop('project-id');
        const lang = this.prop('lang');
        const resetModal: ResetModal = this.jsonProp('reset-modal') || {
            heading: '',
            subheading: '',
        };
        const buttonText = this.prop('button-text');
        const oauthCode = this.prop('oauth-code');

        return (
            <PageRoot>
                <ToastContainer />
                <LoginPage
                    fromUri={fromUri}
                    email={email}
                    projectId={projectId}
                    lang={lang}
                    resetModal={resetModal}
                    buttonText={buttonText}
                    oauthCode={oauthCode}
                />
            </PageRoot>
        );
    }
}

customElements.define('login-page', LoginPageWebComponent);
