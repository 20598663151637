import type { RawProjectLanguage } from '../types';

const findDefaultSourceLanguageId = (languages: RawProjectLanguage | null) => {
    if (!languages) {
        return null;
    }

    const defaultLanguage = Object.values(languages).find((lang) => lang.is_default === '1');
    if (!defaultLanguage) {
        return null;
    }

    return Number(defaultLanguage.lang_id);
};

export default findDefaultSourceLanguageId;
