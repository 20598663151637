import { lazy } from 'react';

import type { EditorGlossaryData } from '@app/api/editor';
import { ReactWebComponent } from '@app/utils/web-components';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const EditorGlossary = lazy(() => import('.'));

class EditorGlossaryComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['data', 'project-id'];
    }

    render() {
        const data = this.jsonProp<EditorGlossaryData>('data');
        const projectId = this.prop('project-id');

        if (!data) {
            return null;
        }

        return (
            <AppProviders>
                <EditorGlossary data={data} projectId={projectId} />
            </AppProviders>
        );
    }
}

customElements.define('editor-glossary', EditorGlossaryComponent);
