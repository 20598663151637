import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const TeamUserGroups = lazy(() => import('.'));

class TeamUserGroupsWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'seats-taken',
            'seats-limit',
            'invited-seats',
            'grace-period-seats',
            'extra-seats-allowed',
            'is-provider-alpha',
            'team-members',
            'group-id',
            'team-name',
            'plan-name',
        ];
    }

    render() {
        const seatsLimit = Number(this.prop('seats-limit'));
        const seatsTaken = Number(this.prop('seats-taken'));
        const invitedSeats = Number(this.prop('invited-seats'));
        const gracePeriodSeats = Number(this.prop('grace-period-seats'));
        const isExtraSeatsAllowed = this.jsonProp('extra-seats-allowed') === 1;
        const isProviderAlpha = this.jsonProp('is-provider-alpha') === 1;
        const teamMembers = this.jsonProp('team-members') as [];
        const groupId = this.getAttribute('group-id');
        const teamName = this.prop('team-name');
        const planName = this.prop('plan-name');

        if (!groupId) {
            return null;
        }

        return (
            <RootProviders>
                <TeamUserGroups
                    planName={planName}
                    teamName={teamName}
                    groupId={groupId}
                    seatsLimit={seatsLimit}
                    seatsTaken={seatsTaken}
                    invitedSeats={invitedSeats}
                    gracePeriodSeats={gracePeriodSeats}
                    isExtraSeatsAllowed={isExtraSeatsAllowed}
                    isProviderAlpha={isProviderAlpha}
                    teamMembers={teamMembers}
                    onClose={() => this.setAttribute('open', 'false')}
                />
            </RootProviders>
        );
    }
}

customElements.define('team-user-groups', TeamUserGroupsWebComponent);
