import type { ParentTask, RawParentTask } from '../types';

const mapParentTasks = (rawParentTasks: RawParentTask[]): ParentTask[] =>
    rawParentTasks.map((task) => ({
        id: task.id,
        title: task.title,
        description: task.description ?? '',
        createdAt: task.created_at,
        sourceLanguage: task.source_lang_id,
        targetLanguages: task.lang_ids,
    }));

export default mapParentTasks;
