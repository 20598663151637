import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { TaskTwigContext, User } from './types';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const TasksPage = lazy(() => import('./pages/Tasks'));

class TasksDashboardWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'is-admin',
            'project-id',
            'branch',
            'user',
            'max-file-uploads',
            'default-locks-for-contributors',
            'isSegmentationEnabled',
        ];
    }

    private getTwigContext(): TaskTwigContext {
        const isSegmentationEnabled = this.jsonProp<number>('isSegmentationEnabled') === 1;

        return {
            isSegmentationEnabled,
        };
    }

    render() {
        const isAdmin = !!this.jsonProp('is-admin');
        const projectId = this.prop('project-id');
        const user = this.jsonProp('user') as User;
        const branch = this.prop('branch');
        const maxFileUploadsParamValue = this.getAttribute('max-file-uploads');
        const forceLockTranslations = !!this.prop('default-locks-for-contributors');

        let maxFileUploads;

        if (maxFileUploadsParamValue != null) {
            maxFileUploads = parseInt(maxFileUploadsParamValue, 10);
        }

        return (
            <PageRoot twigContext={this.getTwigContext()}>
                <TasksPage
                    isAdmin={isAdmin}
                    projectId={projectId}
                    branch={branch}
                    user={user}
                    maxFileUploads={maxFileUploads}
                    forceLockTranslations={forceLockTranslations}
                />
            </PageRoot>
        );
    }
}

customElements.define('tasks-dashboard', TasksDashboardWebComponent);
