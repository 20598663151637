import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const MarketingSupportSelectedAppSlugSetter = lazy(() => import('./MarketingSupportSelectedAppSlug'));

class MarketingSupportSelectedAppSlugSetterComponent extends ReactWebComponent {
    render() {
        return (
            <AppProviders>
                <MarketingSupportSelectedAppSlugSetter />
            </AppProviders>
        );
    }
}

customElements.define('marketing-support-selected-app-slug-setter', MarketingSupportSelectedAppSlugSetterComponent);
