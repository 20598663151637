import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const PayAsYouGoKeyLimitBanner = lazy(() => import('.'));

const RootProviders = lazy(() => import('@app/components/AppProviders'));

class PayAsYouGoKeyLimitBannerWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['key-limit', 'key-count'];
    }

    render() {
        const keyLimit = Number(this.prop('key-limit'));
        const keyCount = Number(this.prop('key-count'));

        return (
            <RootProviders>
                <PayAsYouGoKeyLimitBanner keyLimit={keyLimit} keyCount={keyCount} />
            </RootProviders>
        );
    }
}

customElements.define('pay-as-you-go-key-limit-banner', PayAsYouGoKeyLimitBannerWebComponent);
