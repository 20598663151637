import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const TeamPaymentMethods = lazy(() => import('./TeamPaymentMethods'));

class TeamPaymentMethodsWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [];
    }

    render() {
        return (
            <PageRoot>
                <TeamPaymentMethods />
            </PageRoot>
        );
    }
}

customElements.define('team-payment-methods', TeamPaymentMethodsWebComponent);
