import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const OtaUsageLimitAlert = lazy(() => import('.'));

class OtaUsageLimitAlertComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['team-id', 'ota-usage-limit'];
    }

    render() {
        return (
            <RootProviders>
                <OtaUsageLimitAlert
                    teamId={Number(this.prop('team-id'))}
                    usageLimit={Number(this.prop('ota-usage-limit'))}
                />
            </RootProviders>
        );
    }
}

customElements.define('ota-usage-limit-alert', OtaUsageLimitAlertComponent);
