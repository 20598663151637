import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const OfflineNotification = lazy(() => import('.'));

class OfflineNotificationWebComponent extends ReactWebComponent {
    render() {
        return (
            <RootProviders>
                <OfflineNotification />
            </RootProviders>
        );
    }
}

customElements.define('offline-notification', OfflineNotificationWebComponent);
