import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const DownloadTmxModal = lazy(() => import('.'));

class DownloadTmxModalWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['opened', 'team-id', 'file-id'];
    }

    render() {
        const opened = !!this.jsonProp('opened');
        const teamId = this.prop('team-id');
        const fileId = this.prop('file-id');

        if (!opened || teamId === '' || fileId === '') {
            return null;
        }

        return (
            <RootProviders>
                <DownloadTmxModal
                    onClose={() => {
                        this.dispatchEvent(new Event('downloadtmxmodalclose'));
                    }}
                    teamId={teamId}
                    fileId={fileId}
                />
            </RootProviders>
        );
    }
}

customElements.define('download-tmx-modal', DownloadTmxModalWebComponent);
