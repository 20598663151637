import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { SignupPageTwigContext } from './types';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const RouterProvider = lazy(() => import('@app/components/RouterProvider'));
const SignupRouter = lazy(() => import('./SignupRouter'));
const ToastContainer = lazy(() => import('@lokalise/louis').then((module) => ({ default: module.ToastContainer })));

class SignupPagePartialSpaWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'privacy-sha',
            'terms-sha',
            'project-id',
            'project-name',
            'lang',
            'provider-id',
            'landing-url',
            'recaptcha-key',
        ];
    }

    private getTwigContext(): SignupPageTwigContext {
        return {
            privacySha: this.prop('privacy-sha'),
            termsSha: this.prop('terms-sha'),
            projectId: this.prop('project-id'),
            lang: this.prop('lang'),
            providerId: this.prop('provider-id'),
            landingUrl: this.prop('landing-url'),
            phonePlaceholder: this.prop('phone-placeholder'),
            reCaptchaKey: this.prop('recaptcha-key'),
        };
    }

    render() {
        return (
            <PageRoot twigContext={this.getTwigContext()}>
                <ToastContainer />
                <RouterProvider>
                    <SignupRouter />
                </RouterProvider>
            </PageRoot>
        );
    }
}

customElements.define('signup-page', SignupPagePartialSpaWebComponent);
