import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const AbbreviatedNumber = lazy(() => import('.'));

class AbbreviatedNumberComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['number', 'format'];
    }

    render() {
        const number = this.prop('number');
        const format = this.getAttribute('format') || '%s';

        return (
            <RootProviders>
                <AbbreviatedNumber
                    number={parseInt(number, 10)}
                    formatTooltip={(value) => format.replace('%s', value)}
                />
            </RootProviders>
        );
    }
}

customElements.define('abbreviated-number', AbbreviatedNumberComponent);
