import { lazy } from 'react';

import type { EditorGlossaryData } from '@app/api/editor';
import { ReactWebComponent } from '@app/utils/web-components';

import type { MachineTranslationProps } from '../MachineTranslation';
import type { TranslationMemoryProps } from '../TranslationMemory';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const SidePanel = lazy(() => import('.'));
const SidePanelProvider = lazy(() => import('./components/SidePanelProvider'));
const SidePanelNewEditorWrapper = lazy(() => import('../SidePanelNewEditorWrapper'));

class SidePanelComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'is-selected',
            'trans-id',
            'key-id',
            'plural-form',
            'is-loading',
            'glossary-data',
            'machine-translations-data',
            'translation-memory-data',
            'data-order',
            'from-language-id',
            'to-language-id',
            'tm-feature-available',
            'react-editor',
            'has-translation',
            'lokalise-ai',
            'project-id',
            'base-value',
        ];
    }

    render() {
        const selected = this.prop('is-selected') === 'true';
        const transId = this.prop('trans-id') ?? '';
        const pluralForm = this.prop('plural-form') ?? '';
        const keyId = this.prop('key-id') ?? '';
        const fromLanguageId = this.prop('from-language-id') ?? 0;
        const toLanguageId = this.prop('to-language-id') ?? 0;
        const projectId = this.prop('project-id');
        const baseValue = this.prop('base-value') ?? null;

        const hasTranslation = this.getAttribute('has-translation') === 'true';
        const isLokaliseAiEnabled = this.getAttribute('lokalise-ai') === 'true';

        const loading = this.prop('is-loading') === 'true';
        const dataOrder = this.jsonProp<string[]>('data-order') ?? [];
        const glossaryData = this.jsonProp<EditorGlossaryData>('glossary-data') ?? [];
        const translationMemoryData = this.jsonProp<TranslationMemoryProps>('translation-memory-data') ?? {
            data: [],
            base: '',
            segmentId: transId,
        };

        if (!translationMemoryData.segmentId) {
            translationMemoryData.segmentId = transId;
        }

        const machineTranslationsData = this.jsonProp<MachineTranslationProps>('machine-translations-data') ?? {
            toLanguageRTL: false,
            translationId: '',
            toLanguageId: '',
            referenceLanguageId: '',
            engines: [],
            projectId,
        };
        const tmFeatureAvailable = this.prop('tm-feature-available') === 'true';
        const reactEditor = this.prop('react-editor') === 'true';

        const onTxmEntryDeleted = (txmId: string) => {
            const event = new CustomEvent('ontxmentrydeleted', { detail: txmId });
            this.dispatchEvent(event);
        };

        const onSidePanelUnmounted = () => {
            this.dispatchEvent(new Event('onremoveeventlisteners'));
        };

        if (reactEditor) {
            return (
                <AppProviders>
                    <SidePanelProvider
                        keyId={keyId}
                        pluralForm={pluralForm}
                        fromLanguageId={Number(fromLanguageId)}
                        toLanguageId={Number(toLanguageId)}
                        baseValue={baseValue}
                    >
                        <SidePanelNewEditorWrapper
                            onSidePanelUnmounted={onSidePanelUnmounted}
                            onTxmEntryDeleted={onTxmEntryDeleted}
                            tmFeatureAvailable={tmFeatureAvailable}
                            hasTranslation={hasTranslation}
                            projectId={projectId}
                        />
                    </SidePanelProvider>
                </AppProviders>
            );
        }

        return (
            <AppProviders>
                <SidePanelProvider
                    keyId={keyId}
                    fromLanguageId={Number(fromLanguageId)}
                    toLanguageId={Number(toLanguageId)}
                    pluralForm={pluralForm}
                    baseValue={baseValue}
                >
                    <SidePanel
                        selected={selected}
                        transId={transId}
                        loading={loading}
                        hasTranslation={hasTranslation}
                        dataOrder={dataOrder}
                        glossaryData={glossaryData}
                        machineTranslationsData={machineTranslationsData}
                        translationMemoryData={translationMemoryData}
                        fromLanguageId={Number(fromLanguageId)}
                        toLanguageId={Number(toLanguageId)}
                        tmFeatureAvailable={tmFeatureAvailable}
                        onTxmEntryDeleted={onTxmEntryDeleted}
                        onSidePanelUnmounted={onSidePanelUnmounted}
                        isLokaliseAiEnabled={isLokaliseAiEnabled}
                        projectId={projectId}
                    />
                </SidePanelProvider>
            </AppProviders>
        );
    }
}

customElements.define('editor-side-panel', SidePanelComponent);
