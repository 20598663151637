import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const FindReplaceInfoBox = lazy(() => import('.'));

class FindReplaceInfoBoxComponent extends ReactWebComponent {
    render() {
        return (
            <RootProviders>
                <FindReplaceInfoBox />
            </RootProviders>
        );
    }
}

customElements.define('find-replace-info-box', FindReplaceInfoBoxComponent);
