import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const Highlights = lazy(() => import('.'));

const HighlightComponents = class HighlightComponents extends ReactWebComponent {
    static get observedAttributes() {
        return ['input', 'match', 'exact-match', 'case-sensitive'];
    }

    render() {
        const input = this.prop('input');
        const match = this.prop('match');
        const exactMatch = this.prop('exact-match') === 'true';
        const caseSensitive = this.prop('case-sensitive') === 'true';

        if (!input && !match) {
            return null;
        }

        return <Highlights input={input} match={match} exactMatch={exactMatch} caseSensitive={caseSensitive} />;
    }
};

customElements.define('highlight-string', HighlightComponents);
