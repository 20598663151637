import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const DomainExpiration = lazy(() => import('.'));

class DomainExpirationWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['email', 'domain', 'team-domain-id'];
    }

    render() {
        const email = this.prop('email');
        const domain = this.prop('domain');
        const teamDomainId = this.prop('team-domain-id');

        return (
            <PageRoot>
                <DomainExpiration domain={domain} email={email} teamDomainId={teamDomainId} />
            </PageRoot>
        );
    }
}

customElements.define('domain-expiration', DomainExpirationWebComponent);
