import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const GlobalSetup = lazy(() => import('.'));

class GlobalSetupWebComponent extends ReactWebComponent {
    render() {
        return <GlobalSetup />;
    }
}

customElements.define('lokalise-global-setup', GlobalSetupWebComponent);
