import type { CurrentView, GroupType, Options, PredefinedKeys, ProjectFilterData } from './types';

const prepareOptions = (
    value: string,
    isDocumentOrContentType: boolean,
    data?: ProjectFilterData,
    currentView?: CurrentView,
): Options => {
    if (!data) {
        return [];
    }

    let posinset = 0;

    const incrementPosinset = () => {
        posinset += 1;

        return posinset;
    };

    const getCustomizeValue = () => {
        if (value === '') {
            return [];
        }

        return [
            {
                type: 'option' as const,
                value: 'customize',
                label: 'Customize...',
                posinset: incrementPosinset(),
            },
        ];
    };

    const getNewFilterValue = () => ({
        type: 'option' as const,
        value: 'new_filter',
        label: 'New filter...',
        posinset: incrementPosinset(),
    });

    const getCustomOptions = () => {
        const options = data.custom.map(({ id, label }) => ({
            type: 'option' as const,
            value: id,
            label,
            isRemovable: true,
            posinset: incrementPosinset(),
        }));

        if (data.custom.length > 6) {
            return [
                {
                    type: 'group' as const,
                    title: 'Custom',
                    options,
                },
            ];
        }

        return options;
    };

    const getBuiltInFilterOptions = () =>
        data.builtin_filters.map((item) => {
            if (item.filters) {
                const title = item.label;

                return {
                    type: 'group' as const,
                    title,
                    options: item.filters.map(({ id, label }) => ({
                        type: 'option' as const,
                        value: id,
                        label,
                        posinset: incrementPosinset(),
                    })),
                };
            }

            return {
                type: 'option' as const,
                value: item.id,
                label: item.label,
                posinset: incrementPosinset(),
            };
        });

    const getPredefinedOptions = (key: PredefinedKeys, truncateStart?: boolean): GroupType[] => {
        if (!data.predefined[key]?.length) {
            return [];
        }

        if (key === 'filenames' && currentView === 'document') {
            return [];
        }

        const title = data.predefined[key][0].prefix;

        return [
            {
                type: 'group' as const,
                title,
                options: data.predefined[key].map(({ id, label }) => ({
                    type: 'option' as const,
                    value: `${id}`,
                    label,
                    posinset: incrementPosinset(),
                    truncateStart,
                })),
            },
        ];
    };

    return isDocumentOrContentType
        ? [
              ...getCustomizeValue(),
              getNewFilterValue(),
              ...getCustomOptions(),
              { type: 'divider' as const },
              ...getBuiltInFilterOptions(),
              ...getPredefinedOptions('tags'),
              ...getPredefinedOptions('tasks_uncompleted'),
              ...getPredefinedOptions('tasks_all'),
              ...getPredefinedOptions('filenames', true),
              ...getPredefinedOptions('imports'),
              ...getPredefinedOptions('contexts'),
              ...getPredefinedOptions('cts'),
              ...getPredefinedOptions('orders'),
          ]
        : [
              ...getCustomizeValue(),
              getNewFilterValue(),
              ...getCustomOptions(),
              { type: 'divider' as const },
              ...getBuiltInFilterOptions(),
              ...getPredefinedOptions('tags'),
              ...getPredefinedOptions('platforms'), // Option unique to non-document projects
              ...getPredefinedOptions('tasks_uncompleted'),
              ...getPredefinedOptions('tasks_all'),
              ...getPredefinedOptions('filenames', true),
              ...getPredefinedOptions('imports'),
              ...getPredefinedOptions('contexts'),
              ...getPredefinedOptions('cts'),
              ...getPredefinedOptions('orders'),
          ];
};

export default prepareOptions;
