import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { TwigContext } from './types';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const RouterProvider = lazy(() => import('@app/components/RouterProvider'));
const NewProject = lazy(() => import('./components/NewProject'));

class NewProjectComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['document-project-type-enabled', 'is-segmentation-enabled'];
    }

    render() {
        const documentEnabled = !!this.jsonProp('document-project-type-enabled');
        const isSegmentationEnabled = !!this.jsonProp('is-segmentation-enabled');

        const twigContext: TwigContext = {
            documentProjectTypeEnabled: documentEnabled,
        };

        return (
            <AppProviders twigContext={twigContext}>
                <RouterProvider>
                    <NewProject isSegmentationEnabled={isSegmentationEnabled} />
                </RouterProvider>
            </AppProviders>
        );
    }
}

customElements.define('new-project', NewProjectComponent);
