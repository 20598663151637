import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));

const HtmlParsingRules = lazy(() => import('.'));

class HtmlParsingRulesComponent extends ReactWebComponent {
    render() {
        return (
            <RootProviders>
                <HtmlParsingRules />
            </RootProviders>
        );
    }
}

customElements.define('html-parsing-rules', HtmlParsingRulesComponent);
