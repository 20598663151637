import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import { transformNotificationSettings } from '../../utils';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const Notifications = lazy(() => import('./Notifications'));

class ProfileNotificationsWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['unsubscribe', 'token', 'notification-settings', 'user-fullname'];
    }

    render() {
        const unsubscribe = !!this.prop('unsubscribe');
        const token = this.prop('token') ?? '';
        const userFullname = this.prop('user-fullname') ?? '';
        const twigFormatNotificationSettings =
            this.jsonProp<Record<string, string | null>>('notification-settings') || {};
        const notificationSettings = transformNotificationSettings(twigFormatNotificationSettings);

        return (
            <PageRoot>
                <Notifications
                    unsubscribe={unsubscribe}
                    token={token}
                    notificationSettings={notificationSettings}
                    userFullname={userFullname}
                />
            </PageRoot>
        );
    }
}

customElements.define('profile-notifications', ProfileNotificationsWebComponent);
