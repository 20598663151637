import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import SubscriptionContextProvider from './context/SubscriptionContextProvider';
import type { Section, ValidationFieldLimits } from './types';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const SubscriptionPage = lazy(() => import('.'));
const StripeProvider = lazy(() => import('./StripeProvider'));

class SubscriptionPageWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'section',
            'stripe-key',
            'is-team-invoiced',
            'team-min-seat-limit',
            'all-invoices-paid',
            'has-enterprise-plan',
            'billing-details-validation-field-limits',
            'is-subscriptions-disabled',
        ];
    }

    render() {
        const section = this.prop('section') as Section;
        const stripeKey = this.prop('stripe-key');
        const isTeamInvoiced = this.prop('is-team-invoiced') === 'true';
        const minSeatLimit = Number(this.prop('team-min-seat-limit'));
        const allInvoicesPaid = this.prop('all-invoices-paid') === 'true';
        const enterprisePlan = this.prop('has-enterprise-plan') === 'true';
        const billingDetailsValidationFieldLimits = this.jsonProp<ValidationFieldLimits>(
            'billing-details-validation-field-limits',
        );
        const isSubscriptionDisabled = this.prop('is-subscriptions-disabled') === 'true';

        return (
            <PageRoot>
                <StripeProvider stripeKey={stripeKey}>
                    <SubscriptionContextProvider>
                        <SubscriptionPage
                            section={section}
                            stripeKey={stripeKey}
                            isTeamInvoiced={isTeamInvoiced}
                            minSeatLimit={minSeatLimit}
                            allInvoicesPaid={allInvoicesPaid}
                            enterprisePlan={enterprisePlan}
                            billingDetailsValidationFieldLimits={billingDetailsValidationFieldLimits}
                            isSubscriptionDisabled={isSubscriptionDisabled}
                        />
                    </SubscriptionContextProvider>
                </StripeProvider>
            </PageRoot>
        );
    }
}

customElements.define('subscription-page', SubscriptionPageWebComponent);
