import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const SSOLinkUserPage = lazy(() => import('./SSOLinkUserPage'));

class SSOLinkUserPageWebComponent extends ReactWebComponent {
    render() {
        return (
            <PageRoot>
                <SSOLinkUserPage />
            </PageRoot>
        );
    }
}

customElements.define('sso-link-user-page', SSOLinkUserPageWebComponent);
