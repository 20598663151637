import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { PlanPeriod } from './types';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const TeamBillingOverview = lazy(() => import('./TeamBillingOverview'));

class TeamBillingOverviewWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'plan-id',
            'plan-is-active',
            'plan-is-invoiced',
            'plan-name',
            'plan-expiration-date',
            'plan-is-seat-based',
            'plan-period',
            'plan-period-count',
            'next-cycle-plan-name',
            'next-cycle-plan-is-seat-based',
            'next-cycle-plan-period',
            'next-cycle-plan-period-count',
            'next-cycle-plan-period',
            'next-cycle-plan-seat-limit',
            'scheduled-plan-start-date',
            'disable-subscription-functionality',
            'key-limit',
            'exceeded-key-count',
            'exceeded-key-cost',
            'minutes-since-last-update',
            'team-credit-cents',
            'seats-taken',
            'seats-limit',
            'mau',
            'mau-limit',
            'ota-enabled',
            'ota-limit',
            'team-id',
        ];
    }

    render() {
        const currentPlan = {
            id: Number(this.prop('plan-id')),
            name: this.prop('plan-name'),
            isActive: this.prop('plan-is-active') === '1',
            isInvoiced: this.prop('plan-is-invoiced') === '1',
            isSeatBased: this.prop('plan-is-seat-based') === '1',
            period: this.prop('plan-period') as PlanPeriod,
            periodCount: Number(this.prop('plan-period-count')),
            expirationDate: new Date(this.prop('plan-expiration-date')),
        };

        const scheduledPlanStartDate = this.prop('scheduled-plan-start-date')
            ? new Date(this.prop('scheduled-plan-start-date'))
            : undefined;

        const nextCyclePlan = this.prop('next-cycle-plan-name')
            ? {
                  name: this.prop('next-cycle-plan-name'),
                  isSeatBased: this.prop('next-cycle-plan-is-seat-based') === '1',
                  period: this.prop('next-cycle-plan-period') as PlanPeriod,
                  periodCount: Number(this.prop('next-cycle-plan-period-count')),
                  seatLimit: Number(this.prop('next-cycle-plan-seat-limit')) || 0,
              }
            : undefined;

        const minutesSinceLastUpdate = this.prop('minutes-since-last-update')
            ? Number(this.prop('minutes-since-last-update'))
            : undefined;

        return (
            <PageRoot>
                <TeamBillingOverview
                    currentPlan={currentPlan}
                    nextCyclePlan={nextCyclePlan}
                    scheduledPlanStartDate={scheduledPlanStartDate}
                    keyLimit={Number(this.prop('key-limit')) || 0}
                    exceededKeyCount={Number(this.prop('exceeded-key-count')) || 0}
                    exceededKeyCost={Number(this.prop('exceeded-key-cost')) || 0}
                    minutesSinceLastUpdate={minutesSinceLastUpdate}
                    teamCreditCents={Number(this.prop('team-credit-cents')) || 0}
                    seatsTaken={Number(this.prop('seats-taken')) || 0}
                    seatsLimit={Number(this.prop('seats-limit')) || 0}
                    mau={Number(this.prop('mau')) || 0}
                    mauLimit={Number(this.prop('mau-limit')) || 0}
                    disableSubscriptionFunctionality={this.prop('disable-subscription-functionality') === '1'}
                    otaEnabled={this.prop('ota-enabled') === '1'}
                    otaLimit={Number(this.prop('ota-limit')) || 0}
                    teamId={Number(this.prop('team-id'))}
                />
            </PageRoot>
        );
    }
}

customElements.define('team-billing-overview', TeamBillingOverviewWebComponent);
