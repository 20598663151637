import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import { ProjectType } from '../../types/project';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const EditorEmpty = lazy(() => import('./pages/Empty'));

class EditorEmptyComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['empty', 'project-type', 'no-languages'];
    }

    render() {
        const empty = !!this.jsonProp('empty');
        const noLanguages = !!this.jsonProp('no-languages');
        const projectType = this.jsonProp<ProjectType>('project-type') || ProjectType.localizationFiles;

        return (
            <AppProviders>
                {(empty || noLanguages) && <EditorEmpty projectType={projectType} noLanguages={noLanguages} />}
            </AppProviders>
        );
    }
}

customElements.define('project-editor', EditorEmptyComponent);
