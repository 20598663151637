import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const PageRoot = lazy(() => import('@app/components/PageRoot'));

const ContributorsPage = lazy(() => import('./ContributorsPage'));

const ContributorsContextProvider = lazy(() => import('./context/ContributorsContextProvider'));

class ContributorsPageComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['seats-taken', 'seats-limit', 'invited-seats', 'grace-period-seats', 'team-members'];
    }

    render() {
        const seatsTaken = Number(this.prop('seats-taken'));
        const seatsLimit = Number(this.prop('seats-limit'));
        const invitedSeats = Number(this.prop('invited-seats'));
        const gracePeriodSeats = Number(this.prop('grace-period-seats'));
        const teamMembers =
            this.jsonProp<
                {
                    id: number;
                    email: string;
                    text: string;
                }[]
            >('team-members');
        const isExtraSeatsAllowed = this.jsonProp('is-extra-seats-allowed') === 1;
        const isProviderAlpha = this.jsonProp('is-provider-alpha') === 1;
        const isGroupsEnabled = this.jsonProp('is-groups-enabled') === 1;

        return (
            <PageRoot>
                <ContributorsContextProvider>
                    <ContributorsPage
                        seatsLimit={seatsLimit}
                        seatsTaken={seatsTaken}
                        invitedSeats={invitedSeats}
                        gracePeriodSeats={gracePeriodSeats}
                        teamMembers={teamMembers ?? []}
                        isExtraSeatsAllowed={isExtraSeatsAllowed}
                        isProviderAlpha={isProviderAlpha}
                        isGroupsEnabled={isGroupsEnabled}
                    />
                </ContributorsContextProvider>
            </PageRoot>
        );
    }
}

customElements.define('contributors-page', ContributorsPageComponent);
