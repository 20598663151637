import type { TaskItemStatus } from '@app/api/editor';

export const emitSocketEvent = (event: string, data: Record<string, string | number | boolean | null>) => {
    // @ts-expect-error globalSocket is not defined
    window.globalSocket?.emit(event, data);
};

export const handleTranslationUpdated = (translationId: number, projectId: string, branchId: string | null) => {
    emitSocketEvent('translation updated', {
        translationId,
        projectId,
        branchId,
    });
};

export const handleTaskClosed = (taskId: number, projectId: string) => {
    emitSocketEvent('task closed', {
        task_id: taskId,
        projectId,
    });
    document.dispatchEvent(new CustomEvent('task_closed', { detail: { taskId } }));
};

export const handleTaskLanguageCompleted = (taskId: number, languageId: number, projectId: string) => {
    emitSocketEvent('task lang complete', {
        task_id: taskId,
        lang_id: languageId,
        projectId,
    });

    document.dispatchEvent(
        new CustomEvent('task_lang_complete', {
            detail: {
                taskId,
                languageId,
            },
        }),
    );
};

export const handleTaskItemCompleted = (taskId: number, taskItemId: number, completed: boolean, projectId: string) => {
    emitSocketEvent('task item complete', {
        task_id: taskId,
        task_item_id: taskItemId,
        completed,
        projectId,
    });

    document.dispatchEvent(
        new CustomEvent('task_item_complete', {
            detail: {
                taskId,
                taskItemId,
                completed,
            },
        }),
    );
};

export const completeTaskItemSuccess = (
    closedLanguage: Record<string, never> | null,
    closedTask: Record<string, never> | null,
    status: TaskItemStatus,
    translationId: number,
    projectId: string,
    branchId: string | null,
    taskId: number,
    taskItemId: number,
) => {
    const completed = status === 'completed';
    handleTranslationUpdated(translationId, projectId, branchId);

    if (closedTask) {
        handleTaskClosed(closedTask?.task_id, projectId);
    } else if (closedLanguage) {
        handleTaskLanguageCompleted(closedLanguage?.task_id, closedLanguage?.lang_id, projectId);
    } else {
        handleTaskItemCompleted(taskId, taskItemId, completed, projectId);
    }
};
