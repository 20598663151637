import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { TwigContext } from './types';

const Reporting = lazy(() => import('./Reporting'));
const RootProviders = lazy(() => import('@app/components/AppProviders'));

class ReportingHeaderWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'metabaseTasksUrl',
            'metabaseVolumeUrl',
            'totalTasksCount',
            'totalKeys',
            'totalKeysAddedMoreThanOneDayAgo',
            'totalTasksAddedMoreThanOneDayAgo',
            'maintenanceModeEnabled',
            'maintenanceStartDate',
            'maintenanceEndDate',
        ];
    }

    render() {
        const metabaseBaseUrl = this.prop('metabaseBaseUrl');
        const metabaseTasksUrl = this.prop('metabaseTasksUrl');
        const metabaseVolumeUrl = this.prop('metabaseVolumeUrl');
        const totalKeys = Number(this.prop('totalKeys'));
        const totalKeysAddedMoreThanOneDayAgo = Number(this.prop('totalKeysAddedMoreThanOneDayAgo'));
        const totalTasksCount = Number(this.prop('totalTasksCount'));
        const totalTasksAddedMoreThanOneDayAgo = Number(this.prop('totalTasksAddedMoreThanOneDayAgo'));

        const maintenanceBannerEnabled = Boolean(this.prop('maintenanceBannerEnabled'));
        const maintenanceModeEnabled = Boolean(this.prop('maintenanceModeEnabled'));

        // Expect dates to be in form of UNIX timestamp (UTC)
        const maintenanceStartTimestamp = Number(this.prop('maintenanceStartTimestamp'));
        const maintenanceEndTimestamp = Number(this.prop('maintenanceEndTimestamp'));

        const twigContext: TwigContext = {
            metabaseBaseUrl,
        };

        return (
            <RootProviders twigContext={twigContext}>
                <Reporting
                    totalKeys={totalKeys}
                    totalKeysAddedMoreThanOneDayAgo={totalKeysAddedMoreThanOneDayAgo}
                    totalTasks={totalTasksCount}
                    totalTasksAddedMoreThanOneDayAgo={totalTasksAddedMoreThanOneDayAgo}
                    metabaseTasksUrl={metabaseTasksUrl}
                    metabaseVolumeUrl={metabaseVolumeUrl}
                    maintenanceBannerEnabled={maintenanceBannerEnabled}
                    maintenanceModeEnabled={maintenanceModeEnabled}
                    maintenanceStartTimestamp={maintenanceStartTimestamp}
                    maintenanceEndTimestamp={maintenanceEndTimestamp}
                />
            </RootProviders>
        );
    }
}

customElements.define('reporting-header', ReportingHeaderWebComponent);
