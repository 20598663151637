import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const TrialTopBannerAlert = lazy(() => import('.'));

const RootProviders = lazy(() => import('@app/components/AppProviders'));

class TrialTopBannerAlertWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['trial-days-left'];
    }

    render() {
        const trialDaysLeft = this.prop('trial-days-left');

        return (
            <RootProviders>
                <TrialTopBannerAlert trialDaysLeft={Number(trialDaysLeft)} />
            </RootProviders>
        );
    }
}

customElements.define('trial-top-banner-alert', TrialTopBannerAlertWebComponent);
