import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const EditorToolsContainer = lazy(() => import('.'));

declare global {
    interface HTMLElementTagNameMap {
        'editor-tools': EditorToolsComponent;
    }
}

class EditorToolsComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['files-tool-active', 'show-files-tool', 'is-paged-document'];
    }

    render() {
        const filesToolActive = this.prop('files-tool-active') === 'true';
        const showFilesTool = this.prop('show-files-tool') === 'true';
        const isDocumentOrContentProject = this.prop('is-document-or-content-project') === 'true';

        return (
            <RootProviders>
                <EditorToolsContainer
                    filesToolActive={filesToolActive}
                    showFilesTool={showFilesTool}
                    isDocumentOrContentProject={isDocumentOrContentProject}
                />
            </RootProviders>
        );
    }
}

customElements.define('editor-tools', EditorToolsComponent);
