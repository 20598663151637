import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const RouterProvider = lazy(() => import('@app/components/RouterProvider'));

const AuthRouter = lazy(() => import('./AuthRouter'));

class OnboardingRouterWebComponent extends ReactWebComponent {
    render() {
        return (
            <PageRoot>
                <RouterProvider>
                    <AuthRouter />
                </RouterProvider>
            </PageRoot>
        );
    }
}

customElements.define('auth-router', OnboardingRouterWebComponent);
