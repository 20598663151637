import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import type React from 'react';

const isBugsnagMounted = !!window?.bugsnagInfo?.code;

let started = false;

const start = (react: typeof React | undefined) => {
    if (started || !window?.bugsnagInfo?.code) {
        return;
    }

    started = true;

    Bugsnag.start({
        apiKey: window.bugsnagInfo.code,
        appVersion: window.bugsnagInfo.lokaliseVersion || undefined,
        /*
         * Make sure that React is passed to "BugsnagPluginReact" here instead of in "createErrorBoundary", because
         * otherwise React component inside ReactWebComponent is being rerendered every time "attributeChangedCallback"
         * is called. In other words it is rerendered for every web components attribute.
         * */
        plugins: react ? [new BugsnagPluginReact(react)] : [],
        enabledErrorTypes: {
            // disabling unhandledRejections as our eslint config doesn't allow this, so any remaining errors originate from browser extensions or 3rd parties
            unhandledRejections: false,
        },
        user: {
            email: window.bugsnagInfo.user || undefined,
        },
        onError(event) {
            // returning false on ResizeObserver errors to discard these errors
            // https://docs.bugsnag.com/platforms/javascript/react/customizing-error-reports/#discarding-events
            // https://docs.bugsnag.com/platforms/javascript/react/customizing-error-reports/#the-event-object
            return event.errors[0].errorMessage !== 'ResizeObserver loop limit exceeded';
        },
    });

    Bugsnag.addMetadata('Meta', {
        user: {
            team: window.bugsnagInfo?.team,
        },
    });
};

// Make sure that Bugsnag is initialized in legacy views.
start(undefined);

const log = (message: string) => {
    /*
     * Logging errors in github actions causes the tests to fail. Also when running tests these console logs could get
     * quite annoying, because they might be triggered by a network error, which might be what is expected and actually
     * tested during the test. The console output in these cases is quite large, which clutters the test log.
     * */
    if (process.env.NODE_ENV !== 'test') {
        // eslint-disable-next-line no-console
        console.error(message);
    }

    if (isBugsnagMounted) {
        Bugsnag.notify(message);
    }
};

const getReactPlugin = () => {
    if (!isBugsnagMounted) {
        return undefined;
    }

    return Bugsnag.getPlugin('react');
};

export default {
    start,
    log,
    getReactPlugin,
};
