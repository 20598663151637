import type { ProjectLanguage } from '@app/api/projectLanguages';

import type { ProjectLanguageServerResponse } from './types';

const fromStringToBoolean = (val: string): boolean => val !== '0';

const mapStats = (stats: ProjectLanguageServerResponse['stats'], userIsAdmin: boolean): ProjectLanguage['stats'] => {
    if (stats === null || typeof stats === 'undefined') {
        return null;
    }

    const container = userIsAdmin ? stats.all : stats.not_hidden;

    return {
        percentTranslatedTotal: container.percent_translatedTotal,
        wordCountUntranslated: container.word_count_untranslated,
        wordCount: container.word_count,
        calcIsFuzzy: container.calc_is_fuzzy,
    };
};

const prepareProjectLanguage = (
    backendLanguages: ProjectLanguageServerResponse[],
    userIsAdmin = false,
): ProjectLanguage[] =>
    backendLanguages.map((languageBE) => ({
        id: languageBE.lang_id,
        name: languageBE.name,
        iso: languageBE.lang_iso,
        defaultIso: languageBE.lang_iso_default,
        ccIso: languageBE.cc_iso,
        gengoIso: languageBE.gengo_lang_iso,
        plural: languageBE.plural,
        defaultPlural: languageBE.plural_default,
        default: fromStringToBoolean(languageBE.is_default),
        rtl: fromStringToBoolean(languageBE.rtl),
        editable: fromStringToBoolean(languageBE.is_editable),
        hidden: fromStringToBoolean(languageBE.is_hidden),
        stats: mapStats(languageBE.stats, userIsAdmin),
    }));

export default prepareProjectLanguage;
