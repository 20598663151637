import type { MTEngines, NotificationSettings, Translation } from './types';

export const transformNotificationSettings = (settings: Record<string, string | null>): NotificationSettings =>
    Object.entries(settings).reduce((acc, [key, value]) => {
        let newValue: boolean | string;
        if (value === 'on') {
            newValue = true;
        } else if (value === null) {
            newValue = false;
        } else {
            // keep comments_period as it is
            newValue = value;
        }

        return { ...acc, [key]: newValue };
    }, {} as NotificationSettings);

export const transformMtEngines = (mtEngines: Record<string, number>): MTEngines =>
    Object.keys(mtEngines).reduce((acc, key) => ({ ...acc, [`mt_${key}`]: !!mtEngines[key] }), {}) as MTEngines;

export const convertToTranslationArray = (translationsConfig: Record<string, unknown>[]): Translation[] =>
    translationsConfig.flatMap((item) =>
        Object.keys(item).map((key) => ({ name: key, enabled: !!item[key] }) as Translation),
    );
