import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const UploadFooter = lazy(() => import('.'));

class UploadFooterComponent extends ReactWebComponent {
    render() {
        return (
            <AppProviders>
                <UploadFooter />
            </AppProviders>
        );
    }
}

customElements.define('upload-footer', UploadFooterComponent);
