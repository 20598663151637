import defaultProfile from './defaultProfile';
import ShortcutRegistry from './shortcutRegistry';
import type { ShortcutProfile } from './types';

export type { Listeners } from './shortcutRegistry';

export * from './types';
export { defaultProfile };

export default new ShortcutRegistry({ ...defaultProfile, ...(window.SHORTCUT_PROFILE as ShortcutProfile) });
