import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const EndOfTrialPage = lazy(() => import('.'));

class EndOfTrialPageWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['is-biller'];
    }

    render() {
        const isBiller = Boolean(this.jsonProp<boolean>('is-biller')) ?? false;

        return (
            <PageRoot>
                <EndOfTrialPage isBiller={isBiller} />
            </PageRoot>
        );
    }
}

customElements.define('end-of-trial-page', EndOfTrialPageWebComponent);
