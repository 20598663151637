import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const AppsPage = lazy(() => import('./components/AppsPage'));

class AppsPageComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['project-id'];
    }

    render() {
        const projectId = this.prop('project-id');
        const isAdmin = !!this.jsonProp('is-admin');

        return (
            <RootProviders>
                <AppsPage projectId={projectId} isAdmin={isAdmin} />
            </RootProviders>
        );
    }
}

customElements.define('apps-page', AppsPageComponent);
