import type { PropsWithChildren } from 'react';

import type { Feature } from '@app/hooks/useFeatureState';
import useIsFeatureEnabled from '@app/hooks/useIsFeatureEnabled';

type FeatureFlagProps = {
    flag: Feature;
    removeWhenFlagEnabled?: boolean;
};

const FeatureFlag = ({ flag, removeWhenFlagEnabled, children }: PropsWithChildren<FeatureFlagProps>) => {
    const isFeatureEnabled = useIsFeatureEnabled(flag);

    if (removeWhenFlagEnabled === true) {
        return isFeatureEnabled ? null : <>{children}</>;
    }

    return isFeatureEnabled ? <>{children}</> : null;
};

export default FeatureFlag;
