import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const ActivityToast = lazy(() => import('.'));

class ActivityToastWebComponent extends ReactWebComponent {
    render() {
        return (
            <AppProviders>
                <ActivityToast />
            </AppProviders>
        );
    }
}

customElements.define('activity-toast', ActivityToastWebComponent);
