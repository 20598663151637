import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const DomainVerificationModalContainer = lazy(() => import('./DomainVerificationModalContainer'));

class DomainVerificationModalWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['email'];
    }

    render() {
        const email = this.prop('email');

        return (
            <RootProviders>
                <DomainVerificationModalContainer email={email} />
            </RootProviders>
        );
    }
}

customElements.define('domain-verification-modal', DomainVerificationModalWebComponent);
